<template>
    <div class="container conteudo-card-mensagem">
        <div class="card-cabecalho">
            PREVIEW DA MENSAGEM POR SMS
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div class="text-card-mensagem">
                    <PreviewMessage :tipo="getterTipo.selected" :previsao="getterPrevisao" canal="sms" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PreviewMessage from '@/components/message/PreviewMessage.vue'

import { mapGetters } from 'vuex';

export default {
    components: { PreviewMessage },
    name: 'CardPreviewSMS',
    computed: {
        ...mapGetters('alerta', [
            "getterTipo",
            "getterPrevisao"
        ]),
    },
}
</script>