<template>
  <div class="container-fluid py-4 px-5">
    <div v-if="getterMensagem == 'Alerta enviado com sucesso!'" class="alert alert-success text-center">
      {{ getterMensagem }}
    </div>
    <div v-if="getterMensagem == 'Algo aconteceu'" class="alert alert-danger text-center">
      {{ getterMensagem }}
    </div>
    <div class="title-page">
      <span><img class="icone-title" src="../assets/img/icons/icon-alert.png" /></span>
      <p class="txt-title-page">DISPARAR ALERTA(S)</p>
    </div>
    <div class="row">
      <div class="col-lg-6 col-opcoes">
        <div class="px-0 py-0">
          <card-opcoes-alertas />
        </div>
        <div id="group-btn-dispara-alerta" class="position-relative bottom-0 w-100 d-flex align-content-end my-5">
          <btn-group @click-accept="" />
        </div>
      </div>
      <div class="col-lg-6 cols-cards">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <card-preview-s-m-s />
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 ">
            <card-preview-w-p-p />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardOpcoesAlertas from "../components/cards/CardOpcoesAlertas.vue";
import CardPreviewSMS from "@/components/cards/CardPreviewSMS.vue";
import CardPreviewWPP from "@/components/cards/CardPreviewWPP.vue";
import BtnGroup from "@/components/btn/BtnGroup.vue";
import bootstrapBundleMin from "bootstrap/dist/js/bootstrap.bundle.min";
import { mapGetters, mapActions } from "vuex";


export default {
  components: { CardOpcoesAlertas, CardPreviewSMS, CardPreviewWPP, BtnGroup },
  name: "DisparoAlertas",
  computed: {
    ...mapGetters('alerta', ['getterMensagem']),
  },
  methods: {
    ...mapActions('alerta', ['send']),
    showModal() {
      const myModal = new bootstrapBundleMin.Modal('#modalOk', { keyboard: true });
      myModal.show();
    },
  }
}
</script>