<template>
    <div class="container my-5 conteudo-card-mensagem">
        <div class="card-cabecalho mt-5">
            PREVIEW DA MENSAGEM POR WPP
        </div>
        <div class="card card-mensagem">
            <div class="card-body">
                <div class="sub-title-mensagem ">
                    <p class="text-capitalize">WhatsApp</p>
                    <p class="text-capitalize">Prefeitura de Porto Alegre – DMAE.</p>
                </div>
                <div class="text-card-mensagem">
                    <PreviewMessage :tipo="getterTipo.selected" :previsao="getterPrevisao" canal="wpp" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PreviewMessage from '@/components/message/PreviewMessage.vue'

import { mapGetters } from 'vuex';

export default {
    components: { PreviewMessage },
    name: 'CardPreviewWPP',
    computed: {
        ...mapGetters('alerta', [
            "getterTipo",
            "getterPrevisao"
        ]),
    },
}
</script>