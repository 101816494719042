<template>
    <div>
        <main v-if="canal === 'wpp'">
            <p>
                <strong> Assunto: {{ tipo }} no seu bairro - Departamento Municipal de Água e Esgotos </strong> <br><br>

                Prezado(a) cliente, <br>
                Informamos que o Departamento Municipal de Água e Esgotos (DMAE) retomou os serviços de {{ tipo }} em
                vários bairros de Porto Alegre. <br><br>

                As atividades estão programadas para serem realizadas {{ previsao }}. <br><br>

                Durante este período, nossos agentes estarão realizando visitas às residências cadastradas, devidamente
                identificados com uniformes e crachás, garantindo sua segurança e confiança. <br><br>

                Em caso de dúvidas ou para verificar a identidade dos nossos agentes, por favor, não hesite em ligar
                para o número 156. <br><br>

                Para facilitar o andamento do serviço, por favor, esteja preparado para fornecer as seguintes
                informações:
                CPF e telefone da pessoa responsável pelo ramal de água
                Número de moradores da residência
                Características do imóvel (presença de piscina, caixa d'água, jardim, etc.) <br><br>

                Agradecemos sua colaboração e compreensão. <br><br>

                Atenciosamente,
                Departamento Municipal de Água e Esgotos
            </p>
        </main>
        <main v-else-if="canal === 'sms'">
            <p>
                <strong>{{ tipo }} - Aviso DMAE:</strong><br>
                Prezado(a) cliente, iniciamos {{ tipo }} em Porto Alegre, previsto {{ previsao }}. Agentes
                identificados
                visitarão sua residência. Dúvidas ou confirmação de identidade, ligue 156. Prepare CPF, telefone e
                informações do imóvel. Agradecemos sua colaboração. <br>
                DMAE
            </p>
        </main>
    </div>
</template>

<script>
export default {
    name: "PreviewMessage",
    props: {
        tipo: {
            type: String,
            required: false,
            default: ''
        },
        previsao: {
            type: String,
            required: false,
            default: ''
        },
        canal: {
            type: String,
            required: false,
            default: 'wpp'
        },
    }
}
</script>